import React, { useState, useEffect } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import Dashboard from './Dashboard';
import Login from './Login';
import Register from './Register';

const App = () => {
  const [token, setToken] = useState(localStorage.getItem('token'));
  const [refreshToken, setRefreshToken] = useState(localStorage.getItem('refreshToken'));

  // Centralized API Base URL
  const API_BASE_URL =
    process.env.NODE_ENV === 'production'
      ? 'https://portal.chmstaffing.com/api'
      : 'http://52.25.19.40:3032/api';

  // Login Function
  const login = async (username, password) => {
    try {
      const response = await fetch(`${API_BASE_URL}/auth/login`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password }),
      });

      if (!response.ok) throw new Error('Invalid login credentials');

      const data = await response.json();
      setToken(data.token);
      setRefreshToken(data.refreshToken);
      localStorage.setItem('token', data.token);
      localStorage.setItem('refreshToken', data.refreshToken);
    } catch (error) {
      console.error('Login failed:', error.message);
      alert('Invalid login credentials. Please try again.');
    }
  };

  // Register Function
  const register = async (username, password) => {
    try {
      const response = await fetch(`${API_BASE_URL}/auth/register`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password }),
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || 'Registration failed');
      }

      alert('Registration successful!');
    } catch (error) {
      console.error('Registration failed:', error.message);
      alert(error.message || 'Registration failed.');
    }
  };

  // Logout Function
  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    setToken(null);
    setRefreshToken(null);
  };

  // Refresh Token Logic
  const refreshAuthToken = async () => {
    if (!refreshToken) return;

    try {
      const response = await fetch(`${API_BASE_URL}/auth/refresh-token`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ refreshToken }),
      });

      if (!response.ok) throw new Error('Failed to refresh token');

      const data = await response.json();
      setToken(data.token);
      localStorage.setItem('token', data.token);
    } catch (error) {
      console.error('Token refresh failed:', error.message);
      handleLogout(); // Logout if token refresh fails
    }
  };

  // Auto Refresh Token on Interval
  useEffect(() => {
    const interval = setInterval(() => {
      refreshAuthToken();
    }, 10 * 60 * 1000); // Refresh token every 10 minutes

    return () => clearInterval(interval); // Cleanup on component unmount
  }, [refreshToken]);

  return (
    <Routes>
      {/* Login Route */}
      <Route
        path="/login"
        element={token ? <Navigate to="/" /> : <Login login={login} />}
      />
      {/* Registration Route */}
      <Route
        path="/register"
        element={<Register register={register} />}
      />
      {/* Protected Dashboard Route */}
      <Route
        path="/"
        element={
          token ? (
            <Dashboard handleLogout={handleLogout} />
          ) : (
            <Navigate to="/login" />
          )
        }
      />
    </Routes>
  );
};

export default App;