import React, { useEffect, useState } from 'react';
import './App.css'; // Import the CSS file
import { jwtDecode } from "jwt-decode";
import { useNavigate } from 'react-router-dom'; // React Router for navigation

// Define API_BASE_URL
const API_BASE_URL =
  process.env.NODE_ENV === 'production'
    ? 'https://portal.chmstaffing.com/api' // Use HTTPS for production
    : 'http://52.25.19.40:3032/api'; // Development environment

const Dashboard = ({ handleLogout }) => {
  const [shifts, setShifts] = useState([]);
  const [callOuts, setCallOuts] = useState([]); // Store call-outs
  const [activeTab, setActiveTab] = useState('view'); // 'view', 'addShift', 'addCallOut', or 'editShift'
  const [newShift, setNewShift] = useState({
    shiftName: '',
    status: '',
    notes: '',
    addedBy: '', // Added By field
    callOut: false,
  });

  const [callOutCount, setCallOutCount] = useState(0);  
  const [newCallOut, setNewCallOut] = useState({ employeeName: '', shiftName: '', reason: '' });
  const [editShift, setEditShift] = useState(null); // Store the shift being edited
  const [editedStatus, setEditedStatus] = useState(""); // Store the new status value
  const [token, setToken] = useState(localStorage.getItem('token'));
  const [refreshingToken, setRefreshingToken] = useState(false);
  const navigate = useNavigate();
  const [data, setData] = useState(null); // State for storing fetched data
  const [error, setError] = useState(null); // State for storing error messages

   // Fetch protected data from the API
 // Fetch protected data from the API
 const fetchDashboardData = async () => {
  try {
    const token = localStorage.getItem('token');
    if (!token) {
      handleLogout(); // Logout if token is missing
      return;
    }

    const response = await fetch(`${API_BASE_URL}/dashboard`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`, // Attach the access token
      },
    });

    if (response.status === 401) {
      throw new Error('Unauthorized'); // Handle token expiration
    }

    if (!response.ok) {
      throw new Error('Failed to fetch dashboard data');
    }

    const data = await response.json();
    setData(data); // Store data in state
  } catch (error) {
    console.error('Error fetching dashboard data:', error.message);
    setError(error.message); // Store error message in state

    if (error.message === 'Unauthorized') {
      handleLogout(); // Logout if token is invalid or expired
    }
  }
};

  // Utility to validate JWT token
  const isTokenValid = (token) => {
    try {
      const decoded = jwtDecode(token);
      const currentTime = Math.floor(Date.now() / 1000);
      return decoded.exp > currentTime;
    } catch (error) {
      return false;
    }
  };

  // Automatically refresh token if close to expiration
  const refreshAuthToken = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/auth/refresh`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token }),
      });

      if (!response.ok) throw new Error('Failed to refresh token');

      const { newToken } = await response.json();
      localStorage.setItem('token', newToken);
      setToken(newToken);
      console.log('Token refreshed successfully');
    } catch (error) {
      console.error('Error refreshing token:', error.message);
      handleLogout();
    }
  };

// Handle Edit Button Click
const handleEditClick = (shift) => {
  setEditShift(shift); // Set the shift being edited
  setEditedStatus(shift.status); // Set the initial value for editing
};

// Handle Save Button Click
const handleSaveClick = async () => {
  if (!editShift || !editedStatus) {
    alert('Please select a valid shift and status to save.');
    return;
  }

  try {
    const payload = {
      status: editedStatus,
      notes: editShift.notes || null, // Include updated notes if applicable
    };

    // Send the updated shift data to the backend
    await apiRequest(`/shifts/edit/${editShift.id}`, {
      method: 'PUT',
      body: JSON.stringify(payload),
    });

    alert('Shift updated successfully!');
    setEditShift(null); // Reset the edit state
    fetchShifts(); // Refresh the shifts list
  } catch (error) {
    alert(`Failed to update shift: ${error.message}`);
  }
};
// Function to retrieve the stored token
const getToken = () => localStorage.getItem('token');


// Reusable function to handle API requests
const apiRequest = async (endpoint, options = {}) => {
  try {
    const token = localStorage.getItem('token');
    if (!token) throw new Error('Authentication token is missing. Please log in.');

    const defaultHeaders = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };

    // Remove any leading `/api` from the endpoint
    const cleanEndpoint = endpoint.startsWith('/api') ? endpoint.replace('/api', '') : endpoint;

    // Construct the full URL
    const url = `${API_BASE_URL}${cleanEndpoint}`;

    const response = await fetch(url, {
      ...options,
      headers: {
        ...defaultHeaders,
        ...options.headers,
      },
    });

    if (!response.ok) {
      if ([401, 403].includes(response.status)) {
        throw new Error('Unauthorized access: Please log in again.');
      }
      const errorDetails = await response.text();
      throw new Error(`API Error: ${response.status} ${response.statusText} - ${errorDetails}`);
    }

    return await response.json();
  } catch (error) {
    console.error('API Request Error:', error.message);
    throw error;
  }
};
// Fetch Shifts function
const fetchShifts = async () => {
  try {
    const shiftsData = await apiRequest('/shifts/list');
    setShifts(shiftsData || []);
  } catch (error) {
    alert(`Error fetching shifts: ${error.message}`);
  }
};

  // Monitor token validity
  useEffect(() => {
    const checkTokenValidity = () => {
      if (!token || !isTokenValid(token)) {
        handleLogout();
      } else {
        const decoded = jwtDecode(token);
        const timeLeft = decoded.exp * 1000 - Date.now();

        // Refresh token 5 minutes before expiration
        if (timeLeft < 5 * 60 * 1000 && !refreshingToken) {
          setRefreshingToken(true);
          refreshAuthToken().finally(() => setRefreshingToken(false));
        }
      }
    };

    // Check token validity on mount
    checkTokenValidity();

    // Periodically check token validity
    const interval = setInterval(checkTokenValidity, 1 * 60 * 1000); // Check every minute
    return () => clearInterval(interval);
  }, [token, refreshingToken]);



useEffect(() => {
  const storedToken = localStorage.getItem('token');
  const storedUser = localStorage.getItem('user');
  console.log('Token in localStorage:', storedToken);
  console.log('User in localStorage:', storedUser);

  if (!storedToken || !isTokenValid(storedToken)) {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    setTimeout(() => {
      navigate('/login');
    }, 1000);
    return;
  }

  setToken(storedToken);

  // Decode the token to extract user information
  try {
    const decodedToken = jwtDecode(storedToken);
    const username = decodedToken.username; // Extract username from the token
    setNewShift((prevState) => ({
      ...prevState,
      addedBy: username || 'Unknown', // Set the addedBy field with username
    }));
    // Cache user information in localStorage if not already cached
    if (!storedUser) {
      localStorage.setItem('user', JSON.stringify({ username }));
    }
  } catch (error) {
    console.error('Error decoding token:', error.message);
  }
}, [navigate]);

  // Fetch call-outs from the database
// Fetch call-outs from the database
const fetchCallOuts = async () => {
  try {
    // Make the API request
    const data = await apiRequest('/callouts');

    // Log and update the state with the call-outs data
    console.log('Fetched Call-Outs:', data);

    setCallOuts(data.callOuts || []); // Safely handle if callOuts is undefined
    setCallOutCount(data.callOutCount || 0); // Safely handle if callOutCount is undefined
  } catch (error) {
    console.error('Error fetching call-outs:', error.message);
    alert(`Failed to fetch call-outs: ${error.message}`);
  }
};
  
  useEffect(() => {
    fetchCallOuts();
  }, []);

  useEffect(() => {
    fetchShifts();
    fetchCallOuts();
    const interval = setInterval(() => {
      fetchShifts();
      fetchCallOuts();
    }, 60000); // Refresh every 60 seconds
    return () => clearInterval(interval);
  }, []);
  useEffect(() => {
    fetchDashboardData();
  }, []);
 // Add Shift Form
 const handleAddShift = async (e) => {
  e.preventDefault();

  // Validate required fields
  if (!newShift.shiftName || !newShift.status) {
    alert('Please provide all the required fields.');
    return;
  }

  try {
    // Check if token is present
    const token = localStorage.getItem('token');
    if (!token) {
      throw new Error('Authentication token is missing. Please log in again.');
    }

    // Prepare the payload
    const payload = {
      ...newShift,
      addedBy: newShift.addedBy || 'Unknown', // Fallback if 'addedBy' is missing
    };

    console.log('Submitting Payload:', payload); // Debug payload

    // Send the request
    const response = await fetch(`${API_BASE_URL}/shifts/add`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      const errorDetails = await response.json();
      throw new Error(`Error: ${response.status} ${response.statusText} - ${errorDetails.message}`);
    }

    const result = await response.json();
    console.log('Shift Added Successfully:', result);

    // Refresh shifts or give feedback
    alert('Shift added successfully!');
    fetchShifts();
    setActiveTab('view');
  } catch (error) {
    console.error('Error Adding Shift:', error.message);
    alert(`Failed to add shift: ${error.message}`);
  }
};

const handleAddCallOut = async (e) => {
  e.preventDefault();

  // Validate input fields
  if (!newCallOut.employeeName || !newCallOut.shiftName || !newCallOut.reason) {
    alert('Please provide all the required fields for the call-out.');
    return;
  }

  try {
    // Retrieve token and cached user
    const token = localStorage.getItem('token');
    const cachedUser = JSON.parse(localStorage.getItem('user'));

    if (!token) {
      throw new Error('Authentication token is missing. Please log in again.');
    }
    if (!cachedUser || !cachedUser.username) {
      throw new Error('User information is missing. Please log in again.');
    }

    console.log('Using Cached User:', cachedUser);

    // Construct payload
    const payload = {
      ...newCallOut,
      addedBy: cachedUser.username, // Include the logged-in user's username
    };

    console.log('Payload Before Submission:', payload);

    // Ensure the API endpoint is correct
    const endpoint = `${API_BASE_URL}/shifts/callouts/add`;

    const response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      // Fetch response details for debugging
      const errorDetails = await response.text();
      throw new Error(`Failed to save call-out: ${response.status} ${response.statusText} - ${errorDetails}`);
    }

    const result = await response.json();
    console.log('Call-out added successfully:', result);

    // Provide feedback to the user
    alert('Call-out added successfully!');

    // Refresh call-outs after successful addition
    fetchCallOuts();

    // Clear the form and switch to "View Shifts"
    setNewCallOut({ employeeName: '', shiftName: '', reason: '' });
    setActiveTab('view');
  } catch (error) {
    console.error('Error adding call-out:', error.message);

    // Display a user-friendly error message
    alert(`Error adding call-out: ${error.message}`);
  }
};
  const groupedShifts = {
    filled: shifts.filter((shift) => shift.status === 'Filled'),
    looking: shifts.filter((shift) => shift.status === 'Looking'),
    notFilled: shifts.filter((shift) => shift.status === 'Not Filled'),
    canceled: shifts.filter((shift) => shift.status === 'Canceled'),
  };

  return (
    <div className="dashboard-container">
      <header className="dashboard-header">
        <h1>Welcome to the Dashboard</h1>
        <button onClick={handleLogout} className="logout-button">
          Logout
        </button>
      </header>

          <section className="daily-count">
      <div className="count-item" style={{ backgroundColor: 'green' }}>
        <h3>Filled Shifts</h3>
        <p>{groupedShifts.filled.length}</p>
      </div>
      <div className="count-item" style={{ backgroundColor: 'red' }}>
        <h3>Looking</h3>
        <p>{groupedShifts.looking.length}</p>
      </div>
      <div className="count-item" style={{ backgroundColor: 'orange' }}>
        <h3>Not Filled</h3>
        <p>{groupedShifts.notFilled.length}</p>
      </div>
      <div className="count-item" style={{ backgroundColor: 'gray' }}>
        <h3>Canceled Shifts</h3>
        <p>{groupedShifts.canceled.length}</p>
      </div>
      <div className="count-item" style={{ backgroundColor: 'blue' }}>
          <h3>Call-Outs</h3>
          <p>{callOutCount}</p>
        </div>
    </section>

      <nav className="dashboard-nav">
        <button
          onClick={() => setActiveTab('view')}
          className={`dashboard-tab ${activeTab === 'view' ? 'active' : ''}`}
        >
          View Shifts
        </button>
        <button
          onClick={() => setActiveTab('addShift')}
          className={`dashboard-tab ${activeTab === 'addShift' ? 'active' : ''}`}
        >
          Add Shift
        </button>
        <button
          onClick={() => setActiveTab('addCallOut')}
          className={`dashboard-tab ${activeTab === 'addCallOut' ? 'active' : ''}`}
        >
          Add Call-Out
        </button>
        <button
          onClick={() => setActiveTab('viewCallOuts')}
          className={`dashboard-tab ${activeTab === 'viewCallOuts' ? 'active' : ''}`}
        >
          View Call-Outs
        </button>
      </nav>

      <main className="dashboard-main">
      {activeTab === 'viewCallOuts' && (
          <div className="callouts-container">
            <h2>Call-Outs</h2>
            <table className="table">
              <thead>
                <tr>
                  <th>Employee Name</th>
                  <th>Shift Name</th>
                  <th>Reason</th>
                </tr>
              </thead>
              <tbody>
                {callOuts.map((callOut) => (
                  <tr key={callOut.id}>
                    <td>{callOut.employeeName}</td>
                    <td>{callOut.shiftName}</td>
                    <td>{callOut.reason}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

      {activeTab === 'view' && (
        <div className="table-container">
           <h2>Shift Tracker</h2>
          <table className="table">
            <thead>
              <tr>
                <th>Facility Name</th>
                <th>Status</th>
                <th>Notes</th>
                <th>Added By</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {shifts.map((shift) => (
                <tr key={shift.id}>
                  <td>{shift.shiftName || shift.shift_name}</td>
                  <td>
                    {editShift && editShift.id === shift.id ? (
                      <select
                        value={editedStatus}
                        onChange={(e) => setEditedStatus(e.target.value)}
                      >
                        <option value="Looking">Looking</option>
                        <option value="Filled">Filled</option>
                        <option value="Not Filled">Not Filled</option>
                        <option value="Canceled">Canceled</option>
                      </select>
                    ) : (
                      shift.status
                    )}
                  </td>
                  <td>{shift.notes}</td>
                  <td>{shift.addedBy || "N/A"}</td>
                  <td>
                    {editShift && editShift.id === shift.id ? (
                      <button onClick={handleSaveClick} className="save-button">
                        Save
                      </button>
                    ) : (
                      <button
                        onClick={() => handleEditClick(shift)}
                        className="edit-button"
                      >
                        Edit
                      </button>
                    )}
                  </td>
                </tr>
            ))}
          </tbody>
        </table>
        </div>
      )}
      {activeTab === 'addShift' && (
        <div className="add-shift-form-container">
          <h2>Add a New Shift</h2>
          <form onSubmit={handleAddShift} className="add-shift-form">
            <label>
              Shift Name:
              <input
                type="text"
                value={newShift.shiftName}
                onChange={(e) => setNewShift({ ...newShift, shiftName: e.target.value })}
                required
              />
            </label>
            <label>
              Status:
              <select
                value={newShift.status}
                onChange={(e) => setNewShift({ ...newShift, status: e.target.value })}
                required
              >
                <option value="">Select Status</option>
                  <option value="Looking">Looking</option>
                  <option value="Filled">Filled</option>
                  <option value="Not Filled">Not Filled</option>
                  <option value="Canceled">Canceled</option>
              </select>
            </label>
            <label>
              Notes:
              <textarea
                value={newShift.notes}
                onChange={(e) => setNewShift({ ...newShift, notes: e.target.value })}
              />
            </label>
            <label>
              Added By:
              <input
                type="text"
                value={newShift.addedBy}
                className="disabled-input"
                disabled // Make the input field read-only
              />
            </label>
            
            <button type="submit" className="add-shift-button">
              Add Shift
            </button>
          </form>
        </div>
      )}

        {activeTab === 'addCallOut' && (
          <div className="add-callout-form-container">
            <h2>Add a New Call-Out</h2>
            <form onSubmit={handleAddCallOut} className="add-callout-form">
              <label>
                Employee Name:
                <input
                  type="text"
                  value={newCallOut.employeeName}
                  onChange={(e) => setNewCallOut({ ...newCallOut, employeeName: e.target.value })}
                  required
                />
              </label>
              <label>
                Shift Name:
                <input
                  type="text"
                  value={newCallOut.shiftName}
                  onChange={(e) => setNewCallOut({ ...newCallOut, shiftName: e.target.value })}
                  required
                />
              </label>
              <label>
                Reason:
                <textarea
                  value={newCallOut.reason}
                  onChange={(e) => setNewCallOut({ ...newCallOut, reason: e.target.value })}
                  required
                />
              </label>
              <button type="submit" className="add-callout-button">
                Add Call-Out
              </button>
            </form>
          </div>
        )}
      </main>
    </div>
  );
};

export default Dashboard;